import React from 'react';
import anniversaryBadge from '../../images/DFA_10Year_Primary.png';
import './badge.scss';

const BadgeComponent = ({ isVisible }) => {
  return (
    <div className={`badge-container ${isVisible ? 'badge-visible' : ''}`}>
      <img src={anniversaryBadge} alt="Badge" className="badge-image" />
    </div>
  );
};

export default BadgeComponent;
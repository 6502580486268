import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Grid, Row, Col } from "react-flexbox-grid";
import "./sanity-blog.scss";

const dataset = process.env.GATSBY_SANITY_PROJECT_DATASET ? process.env.GATSBY_SANITY_PROJECT_DATASET : "production";

const SanityBlog = ({ numberOfBlogs, excludePostId, handleShowMore, showMoreButton = true }) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        setLoading(true);
        const query = encodeURIComponent(`*[_type == "post" && _id != "${excludePostId}"]|order(publishedAt desc){_id,title,"categories": categories[]->title,slug,"date":publishedAt,"imageUrl": mainImage.asset->url}`);
        const url = `https://1gj3a3qq.api.sanity.io/v1/data/query/${dataset}?query=${query}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setArticles(data.result);
                setLoading(false);
                setBlogs(data.result.slice(0, numberOfBlogs));
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [excludePostId, numberOfBlogs]);

    function formatDate(dateString) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    function truncateTitle(title) {
        return title.length > 43 ? title.substring(0, 40) + "..." : title;
    }

    const hasMoreArticles = showMoreButton && articles.length > numberOfBlogs;


    return (
        <div className="viewpoints-page">
            <main className="viewpoints-content">
                {loading && <p className="loading">Loading articles...</p>}
                {error && <p>Error: {error}</p>}
                <div className={`${blogs.length === 2 ? 'articles-grid-component' : 'articles-grid-full-page'}`}>
                    {blogs.length > 0 && blogs.map(article => (
                        <Link to={`/news/${article.slug?.current || ""}`} key={article._id} className="article-link">
                            <div className="article">
                                <div className="article-image-container">
                                    {article.imageUrl && (
                                        <img
                                            className="article-image"
                                            src={article.imageUrl}
                                            alt={article.title || "Article Image"}
                                        />
                                    )}
                                </div>
                                <div className="article-content">
                                    <p className="category-title">{article.categories}</p>
                                    <h2 className="article-title">{truncateTitle(article.title)}</h2>
                                    <p className="article-date">{formatDate(article.date)}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {hasMoreArticles && (
                    <div className="show-more-container">
                        <button
                            className="dfa-button-orange"
                            onClick={() => {
                                setLoading(true);
                                handleShowMore();
                            }}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Show More'}
                        </button>
                    </div>
                )}
            </main>
        </div>
    );
};

export default SanityBlog;

import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import SanityBlog from '../components/sanity-blog';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CONSTANTS } from '../utils/constants';
import BadgeComponent from '../components/badge-component';

import '../scss/pages/news.scss';

export const query = graphql`
{
    allSanityPost(limit: 100) {
      nodes {
        publishedAt
        _id
        id
        author {
          name
        }
        categories {
          description
          title
        }
        title
        slug {
          source
        }
        publishedAt
        mainImage {
          asset {
            publicUrl
            url
          }
        }
      }
    }
  }

`;

const News = ({ data }) => {
    const metaTags = {
        name: 'Viewpoints',
        description: 'Deerfield Viewpoints',
        keywords: 'Deerfield, Viewpoints',
    };

    const [visibleRows, setVisibleRows] = useState(3);

    const handleShowMore = () => {
        setVisibleRows(prevRows => prevRows + 2);
    };

    console.log('this is data')
    console.log(data);
    return (
        <Layout meta={metaTags}>
            <div className='viewpoints-hero-container'>
                <ContentBlock
                    outerBgColor={CONSTANTS.BROWN}
                    className='viewpoints-header block'
                >
                    <Grid>
                        <Row>
                            <Col xs={1} md={1}></Col>
                            <Col xs={10} md={10} className='viewpoints-title-block'>
                                <div>
                                    <p className='header-orange'>news & viewpoints</p>
                                    <h1>Driven by passion, proven by results</h1>
                                    <p className='viewpoints-header-copy'>
                                        Our heart and mind lie in healthcare marketing. That's why we're dedicated to sharing trendsetting expertise to help elevate the industry standard at Deerfield and beyond.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={1} md={1}></Col>
                        </Row>
                    </Grid>
                </ContentBlock>
            </div>

            <div className='culture'>
                <ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
                    <Grid>
                        <SanityBlog
                            numberOfBlogs={visibleRows * 3}
                            handleShowMore={handleShowMore}
                        />
                    </Grid>
                </ContentBlock>
            </div>

            <ContentBlock outerBgColor={CONSTANTS.BLUE} className='block block-blue'>
                <Grid>
                    <Row>
                        <Col xs={1} md={2}></Col>
                        <Col xs={10} md={8} className='viewpoints-callout'>
                            <h2>See our expertise in action</h2>
                            <p>
                                Now that you've heard from our agency experts, it’s time to get you in the room with the right one.
                            </p>
                            <a
                                className='dfa-button-orange'
                                href='/solutions'
                                aria-label='find a solution at Deerfield.'
                            >
                                find a solution{' '}
                            </a>
                        </Col>
                        <Col xs={1} md={2}></Col>
                    </Row>
                </Grid>
            </ContentBlock>
            <BadgeComponent isVisible={true} />
        </Layout>
    );
};
export default News;
